import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

const BlogTemplate = ({ pageContext: { title, content }, data }) => {
  return (
    <>
      <Seo
        title={data.strapiBlogs.title.toUpperCase()}
        description={data.strapiBlogs.desc}
        image={data.strapiBlogs.image.publicURL}
      />
      <section className="blog-template">
        <div className="section-blog">
          <article className="blog-content">
            <ReactMarkdown children={data.strapiBlogs.content}/>
          </article>
          <Link to="/blog" className="btn center-btn">
            Ir al blog
          </Link>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query getSingleBlogs($title: String) {
    strapiBlogs(title: { eq: $title }) {
      desc
      title
      content
      image {
        publicURL
      }
    }
  }
`

export default BlogTemplate
